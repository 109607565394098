import __request__ from 'routing/request';
interface IndexSetDefaultTemplate {
    readonly id: string;
}
/**
 * Set index set default template
 * @param body Index set default template id.
 */
export function update(body: IndexSetDefaultTemplate): Promise<unknown> {
    return __request__('PUT', '/system/indices/index_set_defaults', body, {}, {
        'Accept': ['application/json']
    });
}
