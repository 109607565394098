import __request__ from 'routing/request';
interface RuleBuilderDto {
    readonly simulator_message: string;
    readonly rule_builder: RuleBuilder;
    readonly description: string;
    readonly created_at: string;
    readonly id: string;
    readonly source: string;
    readonly title: string;
    readonly modified_at: string;
}
interface RuleBuilderStep {
    readonly step_title: string;
    readonly negate: boolean;
    readonly function: string;
    readonly id: string;
    readonly params: {
        readonly [_key: string]: Object;
    };
    readonly outputvariable: string;
    readonly conditions: RuleBuilderStep[];
    readonly operator: 'AND' | 'OR';
    readonly errors: string[];
}
interface Object {
}
interface RuleBuilder {
    readonly conditions: RuleBuilderStep[];
    readonly actions: RuleBuilderStep[];
    readonly operator: 'AND' | 'OR';
    readonly errors: string[];
}
/**
 * Get available filter rule conditions
 */
export function getConditions(): Promise<unknown> {
    return __request__('GET', '/streams/destinations/filters/builder/conditions', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Run the simulator for the given rule and message
 */
export function simulateRule(): Promise<unknown> {
    return __request__('POST', '/streams/destinations/filters/builder/simulate', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Validate rule builder
 */
export function validateRule(rule: RuleBuilderDto): Promise<unknown> {
    return __request__('POST', '/streams/destinations/filters/builder/validate', rule, {}, {
        'Accept': ['application/json']
    });
}
