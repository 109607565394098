import __request__ from 'routing/request';
interface ShardStatus {
    readonly relocating: number;
    readonly active: number;
    readonly initializing: number;
    readonly unassigned: number;
}
interface ClusterInfo {
    readonly name: string;
    readonly distribution: string;
}
interface ClusterName {
    readonly name: string;
}
interface ClusterHealth {
    readonly shards: ShardStatus;
    readonly status: string;
}
/**
 * Get cluster and shard health overview
 */
export function clusterHealth(): Promise<ClusterHealth> {
    return __request__('GET', '/system/indexer/cluster/health', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get cluster name and distribution
 */
export function clusterInfo(): Promise<ClusterInfo> {
    return __request__('GET', '/system/indexer/cluster/info', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get the cluster name
 */
export function clusterName(): Promise<ClusterName> {
    return __request__('GET', '/system/indexer/cluster/name', null, {}, {
        'Accept': ['application/json']
    });
}
