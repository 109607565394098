import __request__ from 'routing/request';
interface Context {
    readonly streams: {
        readonly [_key: string]: ContextEntity;
    };
    readonly event_definitions: {
        readonly [_key: string]: ContextEntity;
    };
}
interface TimeRange {
    readonly type: string;
}
interface EventsSearchFilter {
    readonly alerts: 'include' | 'exclude' | 'only';
    readonly event_definitions: string[];
}
interface EventsSearchParameters {
    readonly filter: EventsSearchFilter;
    readonly sort_direction: 'asc' | 'desc';
    readonly per_page: number;
    readonly timerange: TimeRange;
    readonly sort_unmapped_type: string;
    readonly query: string;
    readonly sort_by: string;
    readonly page: number;
}
interface EventsSearchResult {
    readonly total_events: number;
    readonly duration: number;
    readonly used_indices: string[];
    readonly context: Context;
    readonly parameters: EventsSearchParameters;
    readonly events: Event[];
}
interface UsedSearchFilter {
}
interface EventDto {
    readonly timerange_end: string;
    readonly timestamp_processing: string;
    readonly origin_context: string;
    readonly scores: {
        readonly [_key: string]: number;
    };
    readonly replay_info: EventReplayInfo;
    readonly streams: string[];
    readonly source_streams: string[];
    readonly source: string;
    readonly priority: number;
    readonly message: string;
    readonly associated_assets: string[];
    readonly group_by_fields: {
        readonly [_key: string]: string;
    };
    readonly key_tuple: string[];
    readonly alert: boolean;
    readonly event_definition_type: string;
    readonly event_definition_id: string;
    readonly id: string;
    readonly fields: {
        readonly [_key: string]: string;
    };
    readonly key: string;
    readonly timestamp: string;
    readonly timerange_start: string;
}
interface Event {
    readonly event: EventDto;
    readonly index_name: string;
    readonly index_type: string;
}
interface EventReplayInfo {
    readonly timerange_end: string;
    readonly query: string;
    readonly streams: string[];
    readonly filters: UsedSearchFilter[];
    readonly timerange_start: string;
}
interface ContextEntity {
    readonly description: string;
    readonly id: string;
    readonly remediation_steps: string;
    readonly title: string;
}
/**
 * Search events
 */
export function search(JSONbody?: EventsSearchParameters): Promise<EventsSearchResult> {
    return __request__('POST', '/events/search', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get event by ID
 */
export function getById(event_id?: string): Promise<Event> {
    return __request__('GET', `/events/${event_id}`, null, {}, {
        'Accept': ['application/json']
    });
}
