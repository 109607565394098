import __request__ from 'routing/request';
interface DeflectorSummary {
    readonly current_target: string;
    readonly is_up: boolean;
}
interface ShardStatus {
    readonly relocating: number;
    readonly active: number;
    readonly initializing: number;
    readonly unassigned: number;
}
interface IndexRangeSummary {
    readonly took_ms: number;
    readonly end: string;
    readonly calculated_at: string;
    readonly index_name: string;
    readonly begin: string;
}
interface IndexSizeSummary {
    readonly deleted: number;
    readonly bytes: number;
    readonly events: number;
}
interface IndexerOverview {
    readonly indices: IndexSummary[];
    readonly deflector: DeflectorSummary;
    readonly counts: MessageCountResponse;
    readonly indexer_cluster: IndexerClusterOverview;
}
interface MessageCountResponse {
    readonly events: number;
}
interface IndexSummary {
    readonly size: IndexSizeSummary;
    readonly tier: 'HOT' | 'WARM';
    readonly shard_count: number;
    readonly range: IndexRangeSummary;
    readonly is_deflector: boolean;
    readonly index_name: string;
    readonly is_reopened: boolean;
    readonly is_closed: boolean;
}
interface ClusterHealth {
    readonly shards: ShardStatus;
    readonly status: string;
}
interface IndexerClusterOverview {
    readonly name: string;
    readonly health: ClusterHealth;
}
/**
 * Get overview of current indexing state, including deflector config, cluster state, index ranges & message counts.
 */
export function index(): Promise<IndexerOverview> {
    return __request__('GET', '/system/indexer/overview', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get overview of current indexing state for the given index set, including deflector config, cluster state, index ranges & message counts.
 */
export function indexByindexSetId(indexSetId?: string): Promise<IndexerOverview> {
    return __request__('GET', `/system/indexer/overview/${indexSetId}`, null, {}, {
        'Accept': ['application/json']
    });
}
