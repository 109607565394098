import __request__ from 'routing/request';
interface ResponseSchemaEntry {
    readonly field: string;
    readonly function: string;
    readonly name: string;
    readonly column_type: 'grouping' | 'metric' | 'field';
    readonly type: 'string' | 'numeric' | 'date' | 'boolean' | 'binary' | 'ip' | 'geo' | 'stream' | 'input' | 'node' | 'unknown';
}
interface TimeRange {
    readonly type: string;
}
interface TabularResponse {
    readonly schema: ResponseSchemaEntry[];
    readonly metadata: Metadata;
    readonly datarows: unknown[][];
}
interface AbsoluteRange {
    readonly from: string;
    readonly to: string;
    readonly type: string;
}
interface MetricConfiguration {
}
interface Metadata {
    readonly effective_timerange: AbsoluteRange;
}
interface MessagesRequestSpec {
    readonly stream_categories: string[];
    readonly timerange: TimeRange;
    readonly size: number;
    readonly query: string;
    readonly streams: string[];
    readonly from: number;
    readonly sort: string;
    readonly fields: string[];
    readonly sort_order: 'Ascending' | 'Descending';
}
type stringArray = string[];
interface Grouping {
    readonly field: string;
    readonly limit: number;
}
interface Metric {
    readonly field: string;
    readonly configuration: MetricConfiguration;
    readonly function: string;
    readonly sort: 'Ascending' | 'Descending';
}
interface AggregationRequestSpec {
    readonly stream_categories: string[];
    readonly timerange: TimeRange;
    readonly query: string;
    readonly streams: string[];
    readonly group_by: Grouping[];
    readonly metrics: Metric[];
}
/**
 * Execute aggregation specified by query parameters
 */
export function aggregateForQueryParameters(query?: string, streams?: stringArray, stream_categories?: stringArray, timerange?: string, groups?: stringArray, metrics?: stringArray): Promise<TabularResponse> {
    return __request__('GET', '/search/aggregate', null, { 'query': query, 'streams': streams, 'stream_categories': stream_categories, 'timerange': timerange, 'groups': groups, 'metrics': metrics }, {
        'Accept': []
    });
}
/**
 * Execute aggregation specified by `searchRequestSpec`
 */
export function aggregateSearchRequestSpec(searchRequestSpec?: AggregationRequestSpec): Promise<TabularResponse> {
    return __request__('POST', '/search/aggregate', searchRequestSpec, {}, {
        'Accept': []
    });
}
/**
 * Execute query specified by query parameters
 */
export function messagesByQueryParameters(query?: string, streams?: stringArray, stream_categories?: stringArray, timerange?: string, fields?: stringArray, sort?: string, sortOrder?: 'Ascending' | 'Descending', from?: number, size?: number): Promise<TabularResponse> {
    return __request__('GET', '/search/messages', null, { 'query': query, 'streams': streams, 'stream_categories': stream_categories, 'timerange': timerange, 'fields': fields, 'sort': sort, 'sortOrder': sortOrder, 'from': from, 'size': size }, {
        'Accept': []
    });
}
/**
 * Execute query specified by `queryRequestSpec`
 */
export function messagesByQueryRequestSpec(queryRequestSpec?: MessagesRequestSpec): Promise<TabularResponse> {
    return __request__('POST', '/search/messages', queryRequestSpec, {}, {
        'Accept': []
    });
}
