import __request__ from 'routing/request';
interface NodeSummary {
    readonly cluster_id: string;
    readonly hostname: string;
    readonly is_master: boolean;
    readonly last_seen: string;
    readonly transport_address: string;
    readonly short_node_id: string;
    readonly is_leader: boolean;
    readonly node_id: string;
}
interface NodeSummaryList {
    readonly total: number;
    readonly nodes: NodeSummary[];
}
/**
 * Information about this node.
 */
export function node(): Promise<NodeSummary> {
    return __request__('GET', '/system/cluster/node', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * List all active nodes in this cluster.
 */
export function nodes(): Promise<NodeSummaryList> {
    return __request__('GET', '/system/cluster/nodes', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Information about a node.
 */
export function nodeBynodeId(nodeId: string): Promise<NodeSummary> {
    return __request__('GET', `/system/cluster/nodes/${nodeId}`, null, {}, {
        'Accept': ['application/json']
    });
}
