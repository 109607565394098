import __request__ from 'routing/request';
type FileArray = File[];
interface CertificateAuthorityInformation {
    readonly id: string;
    readonly type: 'GENERATED' | 'LOCAL' | 'UPLOADED';
}
interface CreateCARequest {
    readonly organization: string;
}
/**
 * Returns the CA
 */
export function get(): Promise<CertificateAuthorityInformation> {
    return __request__('GET', '/ca', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Creates a CA
 */
export function createCA(request: CreateCARequest): Promise<unknown> {
    return __request__('POST', '/ca/create', request, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Upload a CA
 */
export function uploadCA(password?: string, files?: FileArray): Promise<unknown> {
    const formData = new FormData();
    formData.append("password", password);
    files.forEach(f => formData.append("files", f));
    return __request__('POST', '/ca/upload', formData, {}, {
        'Accept': ['application/json']
    });
}
