import __request__ from 'routing/request';
interface Context {
    readonly searched_time_range: TimeRange;
    readonly searched_index_ranges: IndexRangeResult[];
    readonly data_routed_streams: DataRoutedStream[];
}
interface Binding {
    readonly type: string;
}
interface TimeRange {
    readonly type: string;
}
interface ValidationMessageDTO {
    readonly error_title: string;
    readonly error_message: string;
    readonly related_property: string;
    readonly begin_column: number;
    readonly error_type: 'UNDECLARED_PARAMETER' | 'EMPTY_PARAMETER' | 'QUERY_PARSING_ERROR' | 'UNKNOWN_FIELD' | 'INVALID_OPERATOR' | 'MISSING_LICENSE' | 'INVALID_VALUE_TYPE' | 'PARAMETER_NOT_ALLOWED';
    readonly begin_line: number;
    readonly end_line: number;
    readonly end_column: number;
}
interface ValidationResponseDTO {
    readonly context: Context;
    readonly explanations: ValidationMessageDTO[];
    readonly status: 'OK' | 'INFO' | 'WARNING' | 'ERROR';
}
interface Parameter {
    readonly name: string;
    readonly data_type: string;
    readonly binding: Binding;
    readonly description: string;
    readonly optional: boolean;
    readonly default_value: Object;
    readonly title: string;
    readonly type: string;
}
interface Object {
}
interface ValidationRequestDTO {
    readonly parameter_bindings: {
        readonly [_key: string]: Binding;
    };
    readonly filter: BackendQuery;
    readonly stream_categories: string[];
    readonly timerange: TimeRange;
    readonly validation_mode: 'QUERY' | 'SEARCH_FILTER';
    readonly query: BackendQuery;
    readonly streams: string[];
    readonly parameters: Parameter[];
}
interface BackendQuery {
}
interface IndexRangeResult {
    readonly is_warm_tiered: boolean;
    readonly end: number;
    readonly stream_names: string[];
    readonly index_name: string;
    readonly begin: number;
}
interface DataRoutedStream {
    readonly stream_name: string;
    readonly stream_id: string;
    readonly destination: string;
    readonly from: string;
    readonly to: string;
}
/**
 * Validate a search query
 */
export function validateQuery(validationRequest?: ValidationRequestDTO): Promise<ValidationResponseDTO> {
    return __request__('POST', '/search/validate', validationRequest, {}, {
        'Accept': ['application/json']
    });
}
